.buy {
  background-color: var(--romance);
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 926px;
  min-width: 428px;
  overflow: hidden;
  width: 100%;
}

.buy-boxed {
  display: flex;
  height: 75px;
  left: -3px;
  position: fixed;
  top: 1px;
  width: 75px;
  z-index: 2;
}

.close-buy {
  cursor: pointer;
  height: 93.35px;
  margin-left: -9.0px;
  margin-top: -6.0px;
  object-fit: cover;
  transition: all 0.2s ease;
  width: 93.35px;
}

.close-buy:hover {
  filter: blur(3px);
}

.frame-frame-buy {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: calc(50.00% - 202px);
  min-height: 343px;
  position: fixed;
  top: 160px;
  width: 404px;
  z-index: 1;
}

.flex-row-1 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 3px 6px #cecece;
  display: flex;
  height: 238px;
  justify-content: flex-end;
  min-width: 404px;
  padding: 38px 45px;
}

.flex-col {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.0px;
  min-height: 142px;
  width: 84px;
}

.place {
  letter-spacing: 0;
  line-height: 32px;
  min-height: 40px;
  text-align: center;
  text-shadow: 0px 3px 6px #cecece;
  width: 84px;
}

.overlap-group-2 {
  height: 70px;
  margin-left: 5.0px;
  margin-top: 32px;
  position: relative;
  width: 71px;
}

.ellipse-2-2 {
  background-color: var(--romance);
  border-radius: 34.95px;
  height: 70px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 70px;
}

.path-12-2 {
  height: 29px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  top: 15px;
  width: 36px;
}

.path-13-2 {
  height: 23px;
  left: 37px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 20px;
}

.path-14-2 {
  height: 24px;
  left: 52px;
  object-fit: cover;
  position: absolute;
  top: 10px;
  width: 20px;
}

.path-15-2 {
  height: 22px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 34px;
  width: 25px;
}

.path-16-2 {
  height: 33px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 35px;
}

.path-17-2 {
  height: 31px;
  left: 44px;
  object-fit: cover;
  position: absolute;
  top: 30px;
  width: 27px;
}

.path-18-2 {
  height: 30px;
  left: 33px;
  object-fit: cover;
  position: absolute;
  top: 39px;
  width: 24px;
}

.path-19-2 {
  height: 20px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 49px;
  width: 25px;
}

.icon-awesome-dice-0-1 {
  cursor: pointer;
  height: 61px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 1px;
  transition: all 0.2s ease;
  width: 70px;
}

.icon-awesome-dice-0-1:hover {
  transform: scale(0.9);
transform: rotate(360deg);
}

.to-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  min-height: 159px;
  width: 219px;
}

.coin-amount-to-pay {
  background-color: transparent;
  border: 0;
  height: 76px;
  letter-spacing: 0;
  line-height: 62px;
  padding: 0;
  text-align: center;
  width: 219px;
}

.coin-amount-to-pay::placeholder,
.time-to-buy::placeholder {
  color: #70707099;
}

.time-to-buy {
  background-color: transparent;
  border: 0;
  height: 66px;
  letter-spacing: 0;
  line-height: 54px;
  margin-top: 17px;
  padding: 0;
  text-align: center;
  width: 219px;
}

.buy-btn {
  cursor: pointer;
  height: 93px;
  margin-left: 0.52px;
  margin-top: 24px;
  object-fit: cover;
  transition: all 0.2s ease;
  width: 93px;
}

.buy-btn:hover {
  transform: rotate(-180deg);
}

