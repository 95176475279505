.create {
  align-items: center;
  background-color: var(--romance);
  display: flex;
  flex-direction: column;
  height: 926px;
  overflow: hidden;
  width: 428px;
}

.close-create {
  cursor: pointer;
  height: 93px;
  left: -12px;
  object-fit: cover;
  position: fixed;
  top: -5px;
  transition: all 0.2s ease;
  width: 93px;
  z-index: 3;
}

.close-create:hover {
  filter: blur(3px);
}

.nft-frame-create {
  display: flex;
  height: 268px;
  margin-top: 134px;
  width: 404px;
  z-index: 1;
}

.nft-frame-frame-create {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 3px 6px #cecece;
  display: flex;
  flex-direction: column;
  min-height: 268px;
  padding: 22px 16.0px;
  width: 404px;
}

.nft-add-image {
  height: 72px;
  margin-top: 7px;
  object-fit: cover;
  width: 80px;
}

.nft-container-1 {
  align-self: flex-end;
  height: 140px;
  margin-right: 16.0px;
  margin-top: 5px;
  position: relative;
  width: 342px;
}

.nft-title {
  background-color: transparent;
  border: 0;
  height: 40px;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  padding: 0;
  position: absolute;
  top: 0;
  width: 354px;
}

.nft-title::placeholder,
.nft-create-description::placeholder {
  color: #48484899;
}

.nft-create-description {
  background-color: transparent;
  border: 0;
  height: 102px;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  padding: 0;
  position: absolute;
  top: 38px;
  width: 342px;
}

.create-btn {
  cursor: pointer;
  height: 93px;
  margin-left: 0.52px;
  margin-top: 55px;
  object-fit: cover;
  transition: all 0.2s ease;
  width: 93px;
  z-index: 2;
}

.create-btn:hover {
  transform: rotate(-180deg);
}

