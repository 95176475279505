.swap {
  background-color: var(--romance);
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 926px;
  min-width: 428px;
  overflow: hidden;
  width: 100%;
}

.swap-boxed {
  display: flex;
  height: 75px;
  left: -3px;
  position: fixed;
  top: 1px;
  width: 75px;
  z-index: 2;
}

.close-swap {
  cursor: pointer;
  height: 93.35px;
  margin-left: -9.0px;
  margin-top: -6.0px;
  object-fit: cover;
  transition: all 0.2s ease;
  width: 93.35px;
}

.close-swap:hover {
  filter: blur(3px);
}

.frame-frame-swap {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: calc(50.00% - 202px);
  min-height: 343px;
  position: fixed;
  top: 160px;
  width: 404px;
  z-index: 1;
}

.flex-col-1 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 3px 6px #cecece;
  display: flex;
  flex-direction: column;
  min-height: 238px;
  padding: 26.9px 39.1px;
  width: 404px;
}

.flex-row-4 {
  align-items: flex-end;
  align-self: center;
  display: flex;
  margin-right: 1.97px;
  margin-top: 14px;
  min-width: 304px;
}

.overlap-group1-6 {
  height: 70px;
  position: relative;
  width: 71px;
}

.ellipse-2-7 {
  background-color: var(--romance);
  border-radius: 34.95px;
  height: 70px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 70px;
}

.path-12-8 {
  height: 29px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  top: 15px;
  width: 36px;
}

.path-13-8 {
  height: 23px;
  left: 37px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 20px;
}

.path-14-8 {
  height: 24px;
  left: 52px;
  object-fit: cover;
  position: absolute;
  top: 10px;
  width: 20px;
}

.path-15-8 {
  height: 22px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 34px;
  width: 25px;
}

.path-16-8 {
  height: 33px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 35px;
}

.path-17-8 {
  height: 31px;
  left: 44px;
  object-fit: cover;
  position: absolute;
  top: 30px;
  width: 27px;
}

.path-18-8 {
  height: 30px;
  left: 33px;
  object-fit: cover;
  position: absolute;
  top: 39px;
  width: 24px;
}

.path-19-8 {
  height: 20px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 49px;
  width: 25px;
}

.icon-awesome-dice-0-3 {
  cursor: pointer;
  height: 61px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 1px;
  transition: all 0.2s ease;
  width: 70px;
}

.icon-awesome-dice-0-3:hover {
  transform: scale(0.9);
transform: rotate(360deg);
}

.time-amount-to-swap {
  background-color: transparent;
  border: 0;
  height: 66px;
  letter-spacing: 0;
  line-height: 54px;
  margin-bottom: 0.9px;
  margin-left: 14px;
  padding: 0;
  text-align: center;
  width: 219px;
}

.time-amount-to-swap::placeholder,
.tickets-amount-swap::placeholder {
  color: #70707099;
}

.flex-row-5 {
  align-items: center;
  display: flex;
  margin-top: 6px;
  min-width: 319px;
}

.overlap-group-6 {
  background-position: 50% 50%;
  background-size: cover;
  height: 94px;
  position: relative;
  width: 95px;
}

.ellipse-2-8 {
  background-color: var(--romance);
  border-radius: 15.35px;
  height: 31px;
  left: 57px;
  position: absolute;
  top: 58px;
  width: 31px;
}

.path-12-9 {
  height: 13px;
  left: 57px;
  object-fit: cover;
  position: absolute;
  top: 65px;
  width: 16px;
}

.path-13-9 {
  height: 10px;
  left: 73px;
  object-fit: cover;
  position: absolute;
  top: 58px;
  width: 9px;
}

.path-14-9 {
  height: 11px;
  left: 79px;
  object-fit: cover;
  position: absolute;
  top: 63px;
  width: 9px;
}

.path-15-9 {
  height: 10px;
  left: 57px;
  object-fit: cover;
  position: absolute;
  top: 73px;
  width: 11px;
}

.path-16-9 {
  height: 14px;
  left: 61px;
  object-fit: cover;
  position: absolute;
  top: 58px;
  width: 15px;
}

.path-17-9 {
  height: 14px;
  left: 76px;
  object-fit: cover;
  position: absolute;
  top: 71px;
  width: 12px;
}

.path-18-9 {
  height: 13px;
  left: 71px;
  object-fit: cover;
  position: absolute;
  top: 75px;
  width: 10px;
}

.path-19-9 {
  height: 9px;
  left: 62px;
  object-fit: cover;
  position: absolute;
  top: 80px;
  width: 11px;
}

.icon-awesome-dice-6 {
  height: 27px;
  left: 56px;
  object-fit: cover;
  position: absolute;
  top: 59px;
  width: 31px;
}

.tickets-amount-swap {
  background-color: transparent;
  border: 0;
  height: 76px;
  letter-spacing: 0;
  line-height: 62px;
  margin-left: 5px;
  margin-top: 1.87px;
  padding: 0;
  text-align: center;
  width: 219px;
}

.swap-btn {
  cursor: pointer;
  height: 93px;
  margin-left: 0.52px;
  margin-top: 24px;
  object-fit: cover;
  transition: all 0.2s ease;
  width: 93px;
}

.swap-btn:hover {
  transform: rotate(-180deg);
}





.screen {
  min-height: 100vh !important;
  height: 100vh !important;
  overflow: scroll;
}

