.nft-in-wallet {
  align-items: center;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 3px 6px #cecece;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  min-height: 268px;
  padding: 22px 21.0px;
  width: 404px;
}

.flex-row-3 {
  align-items: flex-start;
  display: flex;
  height: 75px;
  margin-right: 44.0px;
  margin-top: 9px;
  min-width: 318px;
}

.nft-in-wallet-image {
  align-self: flex-end;
  height: 72px;
  object-fit: cover;
  width: 80px;
}

.nft-in-wallet-container {
  height: 68px;
  margin-left: 7px;
  position: relative;
  width: 231px;
}

.nft-in-wallet-address {
  left: 0;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  text-shadow: 0px 3px 6px #cecece;
  top: 33px;
  width: 231px;
}

.nft-in-wallet-id {
  left: 0;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  text-shadow: 0px 3px 6px #cecece;
  top: 0;
  width: 168px;
}

.nft-in-wallet-container-1 {
  height: 140px;
  margin-right: 2.0px;
  position: relative;
  width: 342px;
}

.nft-in-wallet-title {
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-shadow: 0px 3px 6px #cecece;
  top: 0;
  width: 84px;
}

.nft-in-wallet-description {
  height: 102px;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-shadow: 0px 3px 6px #cecece;
  top: 38px;
  width: 342px;
}

.nft-in-wallet.nft-in-wallet-1 {
  margin-top: 20px;
}






.nft-in-wallet > div {
  left: 30px;
  position: relative;
}