.landing {
  background-color: var(--romance);
  display: flex;
  height: 100vh;
  min-height: fit-content;
  min-width: 428px;
  width: 100%;
}

.landing-boxed {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 192px;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 99px;
  min-height: calc(100.00% - 291px);
  width: 404px;
  z-index: 1;
}

.loading {
  letter-spacing: 0;
  line-height: 28px;
  margin-left: 2.0px;
  min-height: 35px;
  text-align: center;
  text-shadow: 0px 3px 6px #cecece;
  width: 168px;
}

.guess {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  height: 498px;
  justify-content: center;
  margin-top: 19px;
  min-width: 404px;
  transition: all 0.2s ease;
}


.guess,
.guess-1 {
  transform: scale(0.95); 
}

.guess:hover,
.guess-1:hover {
  transform: scale(0.9); 
}


.overlap-group {
  height: 516px;
  margin-top: -6px;
  position: relative;
  width: 422px;
}

.frame-bottom-guess {
  height: 123px;
  left: 30px;
  object-fit: cover;
  position: absolute;
  top: 393px;
  width: 362px;
}

.word-guess-ticket-cost {
  display: flex;
  height: 68px;
  left: 61px;
  position: absolute;
  top: 417px;
  width: 303px;
}

.tickets-cost-icon {
  align-items: flex-start;
  display: flex;
  min-width: 64.34px;
}

.overlap-group-1 {
  background-position: 50% 50%;
  background-size: cover;
  height: 80px;
  margin-left: -9px;
  margin-top: -6.0px;
  position: relative;
  width: 81px;
}

.ellipse-2 {
  background-color: var(--romance);
  border-radius: 12.56px;
  height: 25px;
  left: 48px;
  position: absolute;
  top: 49px;
  width: 25px;
}

.path-12 {
  height: 10px;
  left: 48px;
  object-fit: cover;
  position: absolute;
  top: 54px;
  width: 13px;
}

.path-13 {
  height: 8px;
  left: 61px;
  object-fit: cover;
  position: absolute;
  top: 49px;
  width: 7px;
}

.path-14 {
  height: 9px;
  left: 66px;
  object-fit: cover;
  position: absolute;
  top: 52px;
  width: 7px;
}

.path-15 {
  height: 8px;
  left: 48px;
  object-fit: cover;
  position: absolute;
  top: 61px;
  width: 9px;
}

.path-16 {
  height: 12px;
  left: 52px;
  object-fit: cover;
  position: absolute;
  top: 49px;
  width: 13px;
}

.path-17 {
  height: 11px;
  left: 64px;
  object-fit: cover;
  position: absolute;
  top: 59px;
  width: 10px;
}

.path-18 {
  height: 11px;
  left: 60px;
  object-fit: cover;
  position: absolute;
  top: 63px;
  width: 8px;
}

.path-19 {
  height: 7px;
  left: 52px;
  object-fit: cover;
  position: absolute;
  top: 66px;
  width: 9px;
}

.icon-awesome-dice {
  height: 22px;
  left: 48px;
  object-fit: cover;
  position: absolute;
  top: 49px;
  width: 25px;
}

.ticket-amount-guess {
  flex: 1;
  letter-spacing: 0;
  line-height: 48px;
  margin-bottom: 0.9px;
  margin-left: 19.5px;
  margin-right: -2.0px;
  margin-top: 4.0px;
  text-align: center;
  text-shadow: 0px 3px 6px #cecece;
}

.overlap-group1 {
  align-items: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: 286px;
  padding: 34px 30px;
  position: absolute;
  top: 129px;
  width: 422px;
}

.flex-row {
  align-items: flex-start;
  display: flex;
  height: 75px;
  margin-top: 3px;
  min-width: 318px;
}

.nft-image-guess {
  align-self: flex-end;
  height: 72px;
  object-fit: cover;
  width: 80px;
}

.guess-container {
  height: 68px;
  margin-left: 7px;
  position: relative;
  width: 231px;
}

.nft-address-guess {
  left: 0;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  text-shadow: 0px 3px 6px #cecece;
  top: 33px;
  width: 231px;
}

.nft-id-guess {
  left: 0;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  text-shadow: 0px 3px 6px #cecece;
  top: 0;
  width: 168px;
}

.guess-container-1 {
  align-self: center;
  height: 140px;
  margin-right: 2.0px;
  position: relative;
  width: 342px;
}

.nft-title-guess {
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-shadow: 0px 3px 6px #cecece;
  top: 0;
  width: 354px;
}

.nft-description-guess {
  height: 102px;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-shadow: 0px 3px 6px #cecece;
  top: 38px;
  width: 342px;
}

.guess-container-2 {
  background-position: 50% 50%;
  background-size: cover;
  height: 151px;
  left: 0;
  position: absolute;
  top: 0;
  width: 422px;
}

.token-frame-guess {
  height: 151px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 422px;
}

.token-image-reward-guess {
  height: 80px;
  left: 37px;
  object-fit: cover;
  position: absolute;
  top: 33px;
  width: 80px;
}

.token-address-reward-guess {
  left: 128px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  text-shadow: 0px 3px 6px #cecece;
  top: 70px;
  width: 231px;
}

.token-amount-reward-guess {
  left: 128px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  text-shadow: 0px 3px 6px #cecece;
  top: 37px;
  width: 168px;
}

.token-name-reward-guess {
  left: 307px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-align: center;
  text-shadow: 0px 3px 6px #cecece;
  top: 35px;
  width: 84px;
}

.guess-1 {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  height: 498px;
  justify-content: center;
  margin-top: 21px;
  min-width: 404px;
  transition: all 0.2s ease;
}

.bottom {
  background-color: var(--white);
  bottom: 0;
  height: 112px;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.time-left {
  bottom: 24px;
  cursor: pointer;
  height: 66px;
  left: calc(49.77% - 109px);
  letter-spacing: 0;
  line-height: 54px;
  position: fixed;
  text-align: center;
  text-shadow: 0px 3px 6px #cecece;
  transition: all 0.2s ease;
  width: 219px;
  z-index: 1;
}

.time-left:hover {
  transform: scale(1.1);
}

.buy-time-btn {
  bottom: 6px;
  cursor: pointer;
  height: 93px;
  object-fit: cover;
  position: fixed;
  right: 10px;
  transition: all 0.2s ease;
  width: 93px;
  z-index: 1;
}

.buy-time-btn:hover {
  transform: rotate(-45deg);
}

.time-token-logo {
  align-items: flex-start;
  bottom: 23px;
  cursor: pointer;
  display: flex;
  height: 70px;
  left: 19px;
  min-width: 71px;
  position: fixed;
  z-index: 99;
}

.overlap-group6 {
  height: 70px;
  margin-top: 0;
  position: relative;
  width: 71px;
}

.ellipse-2-1 {
  background-color: var(--romance);
  border-radius: 34.95px;
  height: 70px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 70px;
}

.path-12-1 {
  height: 29px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  top: 15px;
  width: 36px;
}

.path-13-1 {
  height: 23px;
  left: 37px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 20px;
}

.path-14-1 {
  height: 24px;
  left: 52px;
  object-fit: cover;
  position: absolute;
  top: 10px;
  width: 20px;
}

.path-15-1 {
  height: 22px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 34px;
  width: 25px;
}

.path-16-1 {
  height: 33px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 35px;
}

.path-17-1 {
  height: 31px;
  left: 44px;
  object-fit: cover;
  position: absolute;
  top: 30px;
  width: 27px;
}

.path-18-1 {
  height: 30px;
  left: 33px;
  object-fit: cover;
  position: absolute;
  top: 39px;
  width: 24px;
}

.path-19-1 {
  height: 20px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 49px;
  width: 25px;
}

.icon-awesome-dice-0 {
  cursor: pointer;
  height: 61px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 1px;
  transition: all 0.2s ease;
  width: 70px;
}

.icon-awesome-dice-0:hover {
  transform: scale(0.9);
transform: rotate(360deg);
}

.top {
  background-color: var(--white);
  height: 90px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.logo-guess {
  align-items: flex-end;
  cursor: pointer;
  display: flex;
  height: 74px;
  left: 20px;
  min-width: 183px;
  position: fixed;
  top: 8px;
  transition: all 0.2s ease;
  z-index: 1;
}

.logo-guess:hover {
  transform: translate(15px, 0);
}

.overlap-group7 {
  height: 76px;
  margin-bottom: -2px;
  position: relative;
  width: 185px;
}

.icon-awesome-gift {
  height: 70px;
  left: 28px;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 78px;
}

.g {
  left: 0;
  letter-spacing: 0;
  line-height: 62px;
  position: absolute;
  text-shadow: 0px 3px 6px #cecece;
  top: 0;
  width: 39px;
}

.text-1 {
  left: 101px;
  letter-spacing: 0;
  line-height: 62px;
  position: absolute;
  text-shadow: 0px 3px 6px #cecece;
  top: 0;
  width: 84px;
}

.wallet-header {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  height: 44px;
  min-width: 160px;
  position: fixed;
  right: 19px;
  top: 24px;
  z-index: 1;
}

.wallet-container {
  height: 62px;
  margin-top: -6.0px;
  position: relative;
  width: 169px;
}

.wallet-btn {
  cursor: pointer;
  height: 62px;
  left: 101px;
  object-fit: cover;
  position: absolute;
  top: 0;
  transition: all 0.2s ease;
  width: 68px;
}

.wallet-btn:hover {
  filter: blur(3px);
}

.wallet-address {
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-shadow: 0px 3px 6px #cecece;
  top: 12px;
  width: 112px;
}






/*
.nft-image-guess:hover {
  transform: scale(1.61);
  border: solid thick gray;
  background: white;
  z-index: 9999999999999999999;
}
*/







.landing {
  background-color: var(--romance);
  display: flex;
  height: 100vh;
  min-height: 1362px;
  min-width: 428px;
  position: relative;
  width: 100%;
}

.landing-boxed {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 99px;
  min-height: 803px;
  z-index: 1;
}

.loading {
  letter-spacing: 0;
  line-height: 28px;
  margin-left: 2.0px;
  min-height: 35px;
  text-align: center;
  text-shadow: 0px 3px 6px #cecece;
  width: 168px;
}

.guess {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  height: 756px;
  justify-content: center;
  margin-top: 12px;
  min-width: 404px;
  transition: all 0.2s ease;
}

.overlap-group2 {
  height: 774px;
  margin-top: -6px;
  position: relative;
  width: 422px;
}

.guess-container {
  height: 637px;
  left: 0;
  position: absolute;
  top: 137px;
  width: 422px;
}

.nft-frame-frame-guess {
  height: 286px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 351px;
  width: 422px;
}

.nft-address-guess {
  left: 43px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  text-shadow: 0px 3px 6px #cecece;
  top: 420px;
  width: 237px;
}

.nft-id-guess {
  left: 43px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  text-shadow: 0px 3px 6px #cecece;
  top: 381px;
  width: 196px;
}

.nft-title-guess {
  left: 43px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-shadow: 0px 3px 6px #cecece;
  top: 459px;
  width: 342px;
}

.nft-description-guess {
  height: 102px;
  left: 43px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-shadow: 0px 3px 6px #cecece;
  top: 503px;
  width: 342px;
}

.nft-image-guess {
  height: 366px;
  left: 3px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 415px;
}

.frame-bottom-guess {
  height: 113px;
  left: 280px;
  object-fit: cover;
  position: absolute;
  top: 489px;
  width: 142px;
}

.guess-container-1 {
  background-position: 50% 50%;
  background-size: cover;
  height: 151px;
  left: 0;
  position: absolute;
  top: 0;
  width: 422px;
}

.token-frame-guess {
  height: 151px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 422px;
}

.token-image-reward-guess {
  height: 80px;
  left: 37px;
  object-fit: cover;
  position: absolute;
  top: 33px;
  width: 80px;
}

.token-address-reward-guess {
  left: 128px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  text-shadow: 0px 3px 6px #cecece;
  top: 70px;
  width: 231px;
}

.token-amount-reward-guess {
  left: 128px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  text-shadow: 0px 3px 6px #cecece;
  top: 37px;
  width: 168px;
}

.token-name-reward-guess {
  left: 307px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-align: center;
  text-shadow: 0px 3px 6px #cecece;
  top: 35px;
  width: 84px;
}

.word-guess-ticket-cost {
  display: flex;
  height: 58px;
  justify-content: space-between;
  left: 304px;
  position: absolute;
  top: 509px;
  width: 95px;
}

.tickets-cost-icon {
  align-items: flex-start;
  display: flex;
  margin-bottom: 7.1px;
  margin-top: 7.1px;
  min-width: 41.93px;
}

.overlap-group {
  background-position: 50% 50%;
  background-size: cover;
  height: 59px;
  margin-left: -9px;
  margin-top: -6.0px;
  position: relative;
  width: 59px;
}

.ellipse-2 {
  background-color: var(--romance);
  border-radius: 8.18px;
  height: 16px;
  left: 35px;
  position: absolute;
  top: 34px;
  width: 16px;
}

.path-12 {
  height: 7px;
  left: 35px;
  object-fit: cover;
  position: absolute;
  top: 37px;
  width: 8px;
}

.path-13 {
  height: 5px;
  left: 43px;
  object-fit: cover;
  position: absolute;
  top: 34px;
  width: 5px;
}

.path-14 {
  height: 6px;
  left: 46px;
  object-fit: cover;
  position: absolute;
  top: 36px;
  width: 5px;
}

.path-15 {
  height: 5px;
  left: 35px;
  object-fit: cover;
  position: absolute;
  top: 42px;
  width: 6px;
}

.path-16 {
  height: 8px;
  left: 37px;
  object-fit: cover;
  position: absolute;
  top: 34px;
  width: 8px;
}

.path-17 {
  height: 7px;
  left: 45px;
  object-fit: cover;
  position: absolute;
  top: 41px;
  width: 6px;
}

.path-18 {
  height: 7px;
  left: 42px;
  object-fit: cover;
  position: absolute;
  top: 43px;
  width: 6px;
}

.path-19 {
  height: 5px;
  left: 37px;
  object-fit: cover;
  position: absolute;
  top: 45px;
  width: 6px;
}

.icon-awesome-dice {
  height: 14px;
  left: 34px;
  object-fit: cover;
  position: absolute;
  top: 34px;
  width: 16px;
}

.ticket-amount-guess {
  letter-spacing: 0;
  line-height: 48px;
  margin-bottom: -2px;
  margin-right: -2.0px;
  text-align: center;
  text-shadow: 0px 3px 6px #cecece;
  width: 52.74px;
}

.bottom {
  background-color: var(--white);
  bottom: 0;
  height: 112px;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 2;
}

.time-left {
  bottom: 24px;
  cursor: pointer;
  height: 66px;
  left: calc(49.77% - 109px);
  letter-spacing: 0;
  line-height: 54px;
  position: fixed;
  text-align: center;
  text-shadow: 0px 3px 6px #cecece;
  transition: all 0.2s ease;
  width: 219px;
  z-index: 3;
}

.time-left:hover {
  transform: scale(1.1);
}

.buy-time-btn {
  bottom: 6px;
  cursor: pointer;
  height: 93px;
  object-fit: cover;
  position: fixed;
  right: 10px;
  transition: all 0.2s ease;
  width: 93px;
  z-index: 4;
}

.buy-time-btn:hover {
  transform: rotate(-45deg);
}

.top {
  background-color: var(--white);
  height: 90px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 6;
}

.logo-guess {
  align-items: flex-end;
  cursor: pointer;
  display: flex;
  height: 74px;
  left: 20px;
  min-width: 183px;
  position: fixed;
  top: 8px;
  transition: all 0.2s ease;
  z-index: 7;
}

.logo-guess:hover {
  transform: translate(15px, 0);
}

.overlap-group4 {
  height: 76px;
  margin-bottom: -2px;
  position: relative;
  width: 185px;
}

.icon-awesome-gift {
  height: 70px;
  left: 28px;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 78px;
}

.g {
  left: 0;
  letter-spacing: 0;
  line-height: 62px;
  position: absolute;
  text-shadow: 0px 3px 6px #cecece;
  top: 0;
  width: 39px;
}

.text-1 {
  left: 101px;
  letter-spacing: 0;
  line-height: 62px;
  position: absolute;
  text-shadow: 0px 3px 6px #cecece;
  top: 0;
  width: 84px;
}

.wallet-header {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  height: 44px;
  min-width: 160px;
  position: fixed;
  right: 19px;
  top: 24px;
  z-index: 8;
}

.wallet-container {
  height: 62px;
  margin-top: -6.0px;
  position: relative;
  width: 169px;
}

.wallet-btn {
  cursor: pointer;
  height: 62px;
  left: 101px;
  object-fit: cover;
  position: absolute;
  top: 0;
  transition: all 0.2s ease;
  width: 68px;
}

.wallet-btn:hover {
  filter: blur(3px);
}

.wallet-address {
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-shadow: 0px 3px 6px #cecece;
  top: 12px;
  width: 112px;
}







.screen a {
  left: -10px !important;
  position: relative !important;
  display: block;
}

.nft-image-guess {
  object-fit: contain;
  background: white;
  border: solid thick gray;
  width: 402px;
}

.nft-frame-frame-guess {
  left: -5px;
  width: 418px;
}

.frame-bottom-guess {
  height: 113px;
  left: 279px;
  object-fit: cover;
  position: absolute;
  top: 492px;
  width: 142px;
}
