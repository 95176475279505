.game {
  background-color: var(--romance);
  display: flex;
  height: 100vh;
  min-height: 926px;
  min-width: 428px;
  overflow: hidden;
  width: 100%;
}

.window-game {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 40px;
  min-height: 656px;
  z-index: 1;
}

.overlap-group5 {
  height: 328px;
  margin-top: -6px;
  position: relative;
  width: 422px;
}

.frame-word-guessed {
  height: 122px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 422px;
}

.frame-guess {
  height: 228px;
  left: 147px;
  object-fit: cover;
  position: absolute;
  top: 100px;
  width: 129px;
}

.word-letters-guessed {
  height: 57px;
  left: 34px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-align: center;
  text-shadow: 0px 3px 6px #cecece;
  top: 41px;
  width: 356px;
}

.letter-guessed {
  background-color: transparent;
  border: 0;
  color: var(--dove-gray);
  font-family: var(--font-family-helveticaneue-condensedblack);
  font-size: var(--font-size-l);
  font-weight: 900;
  height: 78px;
  left: 184px;
  letter-spacing: 0;
  line-height: 48px;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 221px;
  width: 57px;
}

.letter-guessed::placeholder {
  color: #70707099;
}

.overlap-group3 {
  align-items: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 114px;
  left: 147px;
  min-width: 129px;
  padding: 14.0px 38.0px;
  position: absolute;
  top: 100px;
}

.icon-awesome-dice-1 {
  cursor: pointer;
  height: 45px;
  object-fit: cover;
  transition: all 0.2s ease;
  width: 52px;
}

.icon-awesome-dice-1:hover {
  transform: rotate(360deg);
}

.info {
  cursor: pointer;
  height: 35px;
  margin-top: 2px;
  object-fit: cover;
  transition: all 0.2s ease;
  width: 35px;
}

.info:hover,
.tickets-stats-frame:hover {
  transform: scale(1.1);
}

.guess-btn {
  align-items: flex-end;
  display: flex;
  height: 83px;
  margin-top: 33px;
  min-width: 387px;
}

.overlap-group1-1 {
  background-position: 50% 50%;
  background-size: cover;
  height: 94px;
  margin-bottom: -5.21px;
  margin-left: -9px;
  position: relative;
  width: 95px;
}

.ellipse-2-3 {
  background-color: var(--romance);
  border-radius: 15.35px;
  height: 31px;
  left: 57px;
  position: absolute;
  top: 58px;
  width: 31px;
}

.path-12-3 {
  height: 13px;
  left: 57px;
  object-fit: cover;
  position: absolute;
  top: 65px;
  width: 16px;
}

.path-13-3 {
  height: 10px;
  left: 73px;
  object-fit: cover;
  position: absolute;
  top: 58px;
  width: 9px;
}

.path-14-3 {
  height: 11px;
  left: 79px;
  object-fit: cover;
  position: absolute;
  top: 63px;
  width: 9px;
}

.path-15-3 {
  height: 10px;
  left: 57px;
  object-fit: cover;
  position: absolute;
  top: 73px;
  width: 11px;
}

.path-16-3 {
  height: 14px;
  left: 61px;
  object-fit: cover;
  position: absolute;
  top: 58px;
  width: 15px;
}

.path-17-3 {
  height: 14px;
  left: 76px;
  object-fit: cover;
  position: absolute;
  top: 71px;
  width: 12px;
}

.path-18-3 {
  height: 13px;
  left: 71px;
  object-fit: cover;
  position: absolute;
  top: 75px;
  width: 10px;
}

.path-19-3 {
  height: 9px;
  left: 62px;
  object-fit: cover;
  position: absolute;
  top: 80px;
  width: 11px;
}

.icon-awesome-dice-2 {
  height: 27px;
  left: 56px;
  object-fit: cover;
  position: absolute;
  top: 59px;
  width: 31px;
}

.guess-container-3 {
  height: 93px;
  margin-bottom: -8.6px;
  margin-left: 1px;
  position: relative;
  width: 309px;
}

.guess-btn-1 {
  cursor: pointer;
  height: 93px;
  left: 217px;
  object-fit: cover;
  position: absolute;
  top: 0;
  transition: all 0.2s ease;
  width: 93px;
}

.guess-btn-1:hover {
  transform: rotate(-90deg);
}

.guess-cost {
  left: 0;
  letter-spacing: 0;
  line-height: 62px;
  position: absolute;
  text-align: center;
  text-shadow: 0px 3px 6px #cecece;
  top: 6px;
  width: 219px;
}

.overlap-group2 {
  height: 131px;
  margin-top: 50px;
  position: relative;
  width: 141px;
}

.b_0 {
  background-color: var(--dove-gray);
  box-shadow: 0px 3px 6px #cecece;
  height: 9px;
  left: 0;
  position: absolute;
  top: 122px;
  width: 86px;
}

.b_1 {
  display: flex;
  height: 122px;
  left: 22px;
  position: absolute;
  top: 8px;
  width: 9px;
}

.rectangle-4 {
  background-color: var(--dove-gray);
  box-shadow: 0px 3px 6px #cecece;
  flex: 1;
  margin-bottom: 56.2px;
  margin-left: -56.2px;
  margin-right: -56.2px;
  margin-top: 56.2px;
  transform: rotate(-90deg);
  width: 121.65px;
}

.b_2 {
  background-color: var(--dove-gray);
  box-shadow: 0px 3px 6px #cecece;
  height: 9px;
  left: 22px;
  position: absolute;
  top: 0;
  width: 93px;
}

.b_3 {
  display: flex;
  height: 45px;
  left: 22px;
  position: absolute;
  top: 0;
  width: 45px;
}

.rectangle-6 {
  background-color: var(--dove-gray);
  box-shadow: 0px 3px 6px #cecece;
  flex: 1;
  margin-bottom: 17.9px;
  margin-left: -4.7px;
  margin-right: -4.7px;
  margin-top: 17.9px;
  transform: rotate(-45.00deg);
  width: 54.39px;
}

.b_4 {
  display: flex;
  flex-direction: column;
  height: 45px;
  justify-content: space-between;
  left: 106px;
  position: absolute;
  top: 0;
  width: 28px;
}

.rectangle-7 {
  background-color: var(--dove-gray);
  box-shadow: 0px 3px 6px #cecece;
  height: 10px;
  margin-top: 6px;
  transform: rotate(90deg);
  width: 22px;
}

.ellipse-1 {
  background-color: var(--white);
  border: 10px solid var(--dove-gray);
  border-radius: 14px;
  box-shadow: 0px 3px 6px #cecece;
  flex: 1;
  height: 28px;
  max-height: 28px;
}

.b_5 {
  display: flex;
  height: 29px;
  left: 115px;
  position: absolute;
  top: 43px;
  width: 10px;
}

.rectangle-8 {
  background-color: var(--dove-gray);
  box-shadow: 0px 3px 6px #cecece;
  flex: 1;
  margin-bottom: 9.5px;
  margin-left: -9.5px;
  margin-right: -9.5px;
  margin-top: 9.5px;
  transform: rotate(90deg);
  width: 29px;
}

.b_7 {
  height: 53px;
  left: 98px;
  position: absolute;
  top: 40px;
  width: 42px;
}

.overlap-group1-2 {
  height: 27px;
  left: 0;
  position: absolute;
  top: 26px;
  width: 42px;
}

.rectangle {
  background-color: var(--dove-gray);
  box-shadow: 0px 3px 6px #cecece;
  height: 9px;
  left: 14px;
  position: absolute;
  top: 9px;
  transform: rotate(45.00deg);
  width: 29px;
}

.rectangle-1 {
  background-color: var(--dove-gray);
  box-shadow: 0px 3px 6px #cecece;
  height: 9px;
  left: -1px;
  position: absolute;
  top: 9px;
  transform: rotate(-45.00deg);
  width: 29px;
}

.overlap-group-3 {
  height: 27px;
  left: 0;
  position: absolute;
  top: 0;
  width: 42px;
}

.layer-white-bottom {
  background-color: var(--white);
  bottom: 0;
  height: 179px;
  left: 0;
  opacity: 0.71;
  position: fixed;
  width: 100%;
  z-index: 2;
}

.stats-game {
  bottom: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 156px;
  justify-content: space-between;
  left: 9px;
  position: fixed;
  width: 310px;
  z-index: 3;
}

.time-stats-frame {
  align-items: flex-end;
  cursor: pointer;
  display: flex;
  flex: 1;
  height: 69.9px;
  justify-content: space-between;
  margin-left: 2.9px;
  margin-right: 5.0px;
  max-height: 69.9px;
  position: relative;
  transition: all 0.2s ease;
}

.time-stats-frame:hover {
  transform: scale(0.9); 
}

.time-left-1 {
  height: 66px;
  letter-spacing: 0;
  line-height: 54px;
  margin-bottom: -15.2px;
  margin-right: -2.0px;
  text-shadow: 0px 3px 6px #cecece;
  width: 219px;
}

.tickets-stats-frame {
  align-items: flex-end;
  cursor: pointer;
  display: flex;
  flex: 1;
  height: 82.92px;
  justify-content: space-between;
  max-height: 82.92px;
  position: relative;
  transition: all 0.2s ease;
}

.tokens-left {
  height: 76px;
  letter-spacing: 0;
  line-height: 62px;
  margin-bottom: -21.1px;
  margin-right: -2.0px;
  text-shadow: 0px 3px 6px #cecece;
  width: 219px;
}

.giftaguess-btn {
  align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  bottom: 23px;
  cursor: pointer;
  display: flex;
  height: 81px;
  position: fixed;
  right: 12px;
  transition: all 0.2s ease;
  width: 72px;
  z-index: 4;
}

.giftaguess-btn:hover {
  transform: translate(0, -15px);
}

.icon-awesome-draw-polygon {
  height: 31.5px;
  margin-bottom: 4.9px;
  margin-left: 20.25px;
  object-fit: cover;
  width: 31.5px;
}

.close-game {
  cursor: pointer;
  height: 93px;
  left: -12px;
  object-fit: cover;
  position: fixed;
  top: -5px;
  transition: all 0.2s ease;
  width: 93px;
  z-index: 5;
}

.close-game:hover {
  filter: blur(3px);
}



.overlap-group2z {
  height: 131px;
  margin-top: 50px;
  position: relative;
  width: 141px;
}






.overlap-group5 {
  margin-left: -10px;
}
.info {
  margin-left: 15px;
}





.screen {
  min-height: 100vh !important;
  height: 100vh !important;
  overflow: scroll;
}

