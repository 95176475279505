@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

@font-face {
  font-family: "HelveticaNeue-CondensedBlack";
  font-style: normal;
  font-weight: 900;
  src: url("static/fonts/HelveticaNeue-CondensedBlack-07.ttf") format("truetype");
}

/* The following line is used to measure usage of this code in production. For more info see our usage billing page */
@import url("https://px.animaapp.com/5f940d2442c75532bd8303f1.5f940d2533fc3f6c437c8e0a.388ejU3.rcp.png");

.screen a {
  display: contents;
  text-decoration: none;
}

.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

.overlay {
  display: none;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.overlay.animate-appear {
  animation: reveal 0.3s ease-in-out 1 normal forwards;
  display: block;
  opacity: 0;
}

.overlay.animate-disappear {
  animation: reveal 0.3s ease-in-out 1 reverse forwards;
  display: block;
  opacity: 1;
}

input:focus {
  outline: none;
}

* {
  box-sizing: border-box;
}



body, body * {
  color: black !important;
}

textarea { resize: none; }

body, body *,
body:focus, body *:focus
body:active, body *:active
body:hover, body *:hover {
  /*
  /*outline: none !important;
  /*-webkit-touch-callout: none; /* iOS Safari */
  /*-webkit-user-select: none; /* Safari */
   /*-khtml-user-select: none; /* Konqueror HTML */
    /* -moz-user-select: none; /* Old versions of Firefox */
     /* -ms-user-select: none; /* Internet Explorer/Edge */
      /*    user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
  

  -webkit-tap-highlight-color: transparent; /* for removing the highlight */

}

@keyframes animatedBackground {
  from {
    background-position: 0px;
  }
  to {
    background-position: 100px;
  }
}

body {
  min-height: 100vh;
  background-image: url('./static/img/bk.jpg');
  /*background-image: url('https://t3.ftcdn.net/jpg/01/99/79/86/240_F_199798666_OxKIVKk2uoN4VBCgLJ2nWJWXKmhyKJoY.jpg');*/
  /*background-image: url('./static/img/pngtree-geometric-memphis-style-seamless-pattern-for-fashion-and-wallpaper-image_118416.png');*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  /*animation: animatedBackground 16.18s linear infinite alternate;*/
}

.landing-boxed, .landing.screen,
.game.screen,
.giftaguess.screen,
.tokens.screen,
.nfts.screen,
.buy.screen,
.create.screen,
.swap-boxed, .swap.screen,
.nft-container,
.info-1.screen
{
  background: none !important;
}


.bottom {
  box-shadow: -2px 7px 7px -2px rgba(72,72,72,0.46) inset;
  -webkit-box-shadow: -2px 7px 7px -2px rgba(72,72,72,0.46) inset;
  -moz-box-shadow: -2px 7px 7px -2px rgba(72,72,72,0.46) inset;
}

.top {
  box-shadow: -2px -7px 7px -2px rgba(72,72,72,0.46) inset;
  -webkit-box-shadow: -2px -7px 7px -2px rgba(72,72,72,0.46) inset;
  -moz-box-shadow: -2px -7px 7px -2px rgba(72,72,72,0.46) inset;
}




.value,
.guess-btn {
  border-bottom: dashed #484848;
  padding-bottom: 10px;
}


.overflow-scrolling {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}


.pointer-cursor {
  cursor: pointer;
}

.pointer-cursor:hover {
  transform: scale(0.9);
}

.icon-awesome-dice-0 {
  object-fit: contain !important;
  height: 75px !important;
  border-radius: 20px;
}


.nfts-boxed a, 
.frame-tokens a {
  left: 0 !important;
}






.web3modal-modal-card {
  border: solid gray !important;
}

/*
.web3modal-modal-lightbox {
  background-color: transparent !important;
}
*/



.loading_ {
  /*position: fixed;*/
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: 50px auto;
  width: fit-content !important;  /*
  left: 0;
  bottom: 0;
  right: 0;
  */
}


/* Transparent Overlay */
/*
.loading_:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}
*/

/* :not(:required) hides these rules from IE9 and below */
.loading_:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading_:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}









.border-5px-dove-gray {
  background-image: url('./static/img/frame.jpg');
  background-size: 100% 100%;
}


.border-5px-dove-gray,
.nft-image-guess,
.nft-image-guess {
  border: none !important;
}

.in-wallet-token-image,
.posted-next,
.attatch-token-btn,
.attatch-nft-btn,
.wallet-btn,
.giftaguess-btn,
.icon-awesome-gift,
.frame-word-to-guess,
.frame-attatched,
.token-frame-guess,
.frame-word-guessed,
.frame-guess,
.frame-bottom-guess,
.nft-frame-frame-guess,
.token-frame-guess {
  object-fit: fill !important;
  /*width: 100% !important;*/
  border-radius: 30px !important;
}

.push_btn {
  border-radius: 100px !important;
  border: solid 10px transparent;
}


.nft-image-guess {
  z-index: -1;
}

.icon-awesome-dice-1 {
  /*border: solid thick #feb01a;*/
  border-radius: 50px;
}



.top, .bottom {
  background-color: #f4f4f491 !important;
}



.giftaguess.screen .frame-attatched,
.giftaguess.screen .frame-word-to-guess {
  border: solid 15px transparent;
}

/*
.close-giftaguess,
.close-game,
.close-swap,
.close-buy,
.close-create,
.close-nfts,
.close-tokens {

}
*/

.close-giftaguess,
.close-game,
.close-swap,
.close-buy,
.close-create,
.close-nfts,
.close-tokens,
.guessed-check,
.create-nft-btn,
.info {
  filter: contrast(1000%);
}



.nft-frame-frame-create.border-5px-dove-gray > div {
  border-radius: 20px;
  position: relative;
  left: 35px;
  top: 20px;
}



.layer-white-bottom {
  display: none !important;
}

.overlap-group2z * {
  filter: contrast(1000%);
}

.frame-bottom-guess {
  left: 278px !important;
  top: 488px !important;
}



.close-giftaguess:hover,
.close-game:hover,
.close-swap:hover,
.close-buy:hover,
.close-create:hover,
.close-nfts:hover,
.close-tokens:hover,
.create-nft-btn:hover,
.info:hover {
  transform: scale(0.9) !important;
  filter: contrast(1000%) !important;
}

.push_btn:hover {
  filter: hue-rotate(210deg) !important;
}




.screen {
  min-height: 100vh !important;
  height: 100vh !important;
  width: 100vw !important;
  overflow: scroll !important;
}



.landing {
  min-height: 100vh !important;
  height: 100vh !important;
  overflow: scroll;
}

#WEB3_CONNECT_MODAL_ID * {
  z-index: 99;
}

.web3modal-modal-card {
  border: solid 10px #000 !important;
}





.push_btn,
.wallet-btn,
.create-nft-btn,
.giftaguess-btn,
.attatch-token-btn,
.attatch-nft-btn,
.icon-awesome-gift,
.icon-awesome-dice-0,
.time-left, .time-left-1,
.nft-frame-frame-create.border-5px-dove-gray > div {
  animation: animatedImgs 3s linear infinite alternate;
}


@keyframes animatedImgs {
  from {
   transform: scale(1.0);
  }
  to {
    transform: scale(0.95);
  }
}









.nft-image-guess {
  border: solid 10px #59d9dc !important;
  background: linear-gradient(180deg, rgb(181, 237, 236) 0%, rgb(255, 255, 255) 15%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 85%, rgb(181, 237, 236) 100%) !important;
}


.token-image-reward-guess {
  height: 70px !important;
  object-fit: contain !important;
  left: 45px !important;
}


.close-buy {
  margin-left: 8px !important;
}




.intro-box {
  border: solid 15px;
  height: fit-content !important;
  padding: 10px;
  background: #ffffffc9;
  border-radius: 30px;
}

.overlap-group-container {
  margin-left: 10px !important;
}



img.info {
  margin-top: 15px !important;
}



.swal2-container * {
  font-family: var(--font-family-helveticaneue-condensedblack) !important;
  font-size: 14px !important;
  width: auto !important;
}

button {
  font-family: var(--font-family-helveticaneue-condensedblack) !important;
  font-size: 14px !important;
  background-color: white !important;
  color: grey !important;
  border: none !important;
  box-shadow: grey !important;
}


.giftaguess-btn:hover {
  transform: scale(0.7) !important;
}