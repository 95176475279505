:root { 
  --dove-gray: #707070;
  --gravel: #484848;
  --romance: #fdfdfd;
  --white: #ffffff;
 
  --font-size-l: 48px;
  --font-size-m: 32px;
  --font-size-s: 28px;
  --font-size-xl: 54px;
  --font-size-xs: 26px;
  --font-size-xxl: 62px;
 
  --font-family-helveticaneue-condensedblack: "HelveticaNeue-CondensedBlack", Helvetica;
}
.helveticaneue-condensed-black-gravel-32px {
  color: var(--gravel);
  font-family: var(--font-family-helveticaneue-condensedblack);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 900;
}

.helveticaneue-condensed-black-gravel-28px {
  color: var(--gravel);
  font-family: var(--font-family-helveticaneue-condensedblack);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 900;
}

.helveticaneue-condensed-black-dove-gray-54px {
  color: var(--dove-gray);
  font-family: var(--font-family-helveticaneue-condensedblack);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 900;
}

.helveticaneue-condensed-black-dove-gray-62px {
  color: var(--dove-gray);
  font-family: var(--font-family-helveticaneue-condensedblack);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 900;
}

.helveticaneue-condensed-black-gravel-48px {
  color: var(--gravel);
  font-family: var(--font-family-helveticaneue-condensedblack);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 900;
}

.helveticaneue-condensed-black-gravel-62px {
  color: var(--gravel);
  font-family: var(--font-family-helveticaneue-condensedblack);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 900;
}

.helveticaneue-condensed-black-dove-gray-32px {
  color: var(--dove-gray);
  font-family: var(--font-family-helveticaneue-condensedblack);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 900;
}

.border-5px-dove-gray {
  border: 5px solid var(--dove-gray);
}
