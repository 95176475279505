.tokens {
  align-items: center;
  background-color: var(--romance);
  display: flex;
  flex-direction: column;
  height: 926px;
  overflow: hidden;
  width: 428px;
}

.close-tokens {
  cursor: pointer;
  height: 93px;
  left: -12px;
  object-fit: cover;
  position: fixed;
  top: -5px;
  transition: all 0.2s ease;
  width: 93px;
  z-index: 3;
}

.close-tokens:hover {
  filter: blur(3px);
}

.loading-tokens {
  letter-spacing: 0;
  line-height: 28px;
  margin-left: 2.0px;
  margin-top: 22px;
  min-height: 35px;
  text-align: center;
  text-shadow: 0px 3px 6px #cecece;
  width: 168px;
  z-index: 1;
}

.frame-tokens {
  display: flex;
  flex-direction: column;
  height: 273px;
  margin-top: 48px;
  position: relative;
  width: 404px;
  z-index: 2;
}






.landing {
  min-height: 100vh !important;
  height: 100vh !important;
  overflow: scroll;
}