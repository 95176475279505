.time-token-logo-1 {
  align-items: flex-start;
  display: flex;
  min-width: 71.14px;
}

.overlap-group-4 {
  height: 70px;
  margin-top: 0;
  position: relative;
  width: 71px;
}

.ellipse-2-4 {
  background-color: var(--romance);
  border-radius: 34.95px;
  height: 70px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 70px;
}

.path-12-4 {
  height: 29px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  top: 15px;
  width: 36px;
}

.path-13-4 {
  height: 23px;
  left: 37px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 20px;
}

.path-14-4 {
  height: 24px;
  left: 52px;
  object-fit: cover;
  position: absolute;
  top: 10px;
  width: 20px;
}

.path-15-4 {
  height: 22px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 34px;
  width: 25px;
}

.path-16-4 {
  height: 33px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 35px;
}

.path-17-4 {
  height: 31px;
  left: 44px;
  object-fit: cover;
  position: absolute;
  top: 30px;
  width: 27px;
}

.path-18-4 {
  height: 30px;
  left: 33px;
  object-fit: cover;
  position: absolute;
  top: 39px;
  width: 24px;
}

.path-19-4 {
  height: 20px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 49px;
  width: 25px;
}

.icon-awesome-dice-0-2 {
  height: 61px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 70px;
}

