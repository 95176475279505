.tickets-cost-icon-1 {
  align-items: flex-start;
  display: flex;
  min-width: 78.67px;
}

.overlap-group1-3 {
  background-image: url(/static/img/icon-material-sort-by-alpha-1@1x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 94px;
  margin-left: -9px;
  margin-top: -6.0px;
  position: relative;
  width: 95px;
}

.ellipse-2-5 {
  background-color: var(--romance);
  border-radius: 15.35px;
  height: 31px;
  left: 57px;
  position: absolute;
  top: 58px;
  width: 31px;
}

.path-12-5 {
  height: 13px;
  left: 57px;
  object-fit: cover;
  position: absolute;
  top: 65px;
  width: 16px;
}

.path-13-5 {
  height: 10px;
  left: 73px;
  object-fit: cover;
  position: absolute;
  top: 58px;
  width: 9px;
}

.path-14-5 {
  height: 11px;
  left: 79px;
  object-fit: cover;
  position: absolute;
  top: 63px;
  width: 9px;
}

.path-15-5 {
  height: 10px;
  left: 57px;
  object-fit: cover;
  position: absolute;
  top: 73px;
  width: 11px;
}

.path-16-5 {
  height: 14px;
  left: 61px;
  object-fit: cover;
  position: absolute;
  top: 58px;
  width: 15px;
}

.path-17-5 {
  height: 14px;
  left: 76px;
  object-fit: cover;
  position: absolute;
  top: 71px;
  width: 12px;
}

.path-18-5 {
  height: 13px;
  left: 71px;
  object-fit: cover;
  position: absolute;
  top: 75px;
  width: 10px;
}

.path-19-5 {
  height: 9px;
  left: 62px;
  object-fit: cover;
  position: absolute;
  top: 80px;
  width: 11px;
}

.icon-awesome-dice-3 {
  height: 27px;
  left: 56px;
  object-fit: cover;
  position: absolute;
  top: 59px;
  width: 31px;
}

