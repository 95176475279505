.token-frame {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 3px 6px #cecece;
  display: flex;
  justify-content: flex-end;
  min-width: 404px;
  padding: 22px;
}

.in-wallet-token-image {
  height: 80px;
  object-fit: cover;
  width: 80px;
}

.in-wallet-token-container {
  align-self: center;
  height: 70px;
  margin-bottom: 5.0px;
  margin-left: 16px;
  position: relative;
  width: 263px;
}

.in-wallet-token-address {
  left: 0;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  text-shadow: 0px 3px 6px #cecece;
  top: 35px;
  width: 231px;
}

.in-wallet-token-amount {
  left: 0;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  text-shadow: 0px 3px 6px #cecece;
  top: 2px;
  width: 168px;
}

.in-wallet-token-name {
  left: 179px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-align: center;
  text-shadow: 0px 3px 6px #cecece;
  top: 0;
  width: 84px;
}

.token-frame.token-frame-1 {
  margin-top: 7px;
}






