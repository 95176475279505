.transaction {
  display: flex;
  height: 100vh;
  justify-content: flex-end;
  min-height: 330px;
  min-width: 428px;
  width: 100%;
  z-index: 999999;
  position: fixed;
}

.overlay .transaction {
  background-color: #00000000;
}

.transaction-bubble {
  align-items: flex-start;
  display: flex;
  height: 292.61px;
  margin-right: 14.6px;
  margin-top: 11.8px;
  min-width: 398.72px;
}

.transaction-container {
  height: 299px;
  margin-top: -6.0px;
  position: relative;
  width: 408px;
}

.transaction-info-frame {
  display: flex;
  height: 254px;
  left: 0;
  position: absolute;
  top: 44px;
  width: 383px;
}

.transaction-frame-frame {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 3px 6px #cecece;
  display: flex;
  flex-direction: column;
  height: 254.28px;
  width: 383.32px;
}

.transaction-title {
  height: 40px;
  letter-spacing: 0;
  line-height: 32px;
  margin-left: 28.5px;
  margin-top: 31.3px;
  text-shadow: 0px 3px 6px #cecece;
  width: 340px;
}

.transaction-description {
  height: 150.01px;
  letter-spacing: 0;
  line-height: 32px;
  margin-left: 28.5px;
  margin-top: 12.2px;
  text-shadow: 0px 3px 6px #cecece;
  width: 324.6px;
}

.close-transaction {
  cursor: pointer;
  height: 89px;
  left: 318px;
  object-fit: cover;
  position: absolute;
  top: 0;
  transition: all 0.2s ease;
  width: 89px;
}

.close-transaction:hover {
  filter: blur(3px);
}

#overlay-transaction {
  background-color: transparent;
}

