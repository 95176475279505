.giftaguess {
  align-items: center;
  background-color: var(--romance);
  display: flex;
  flex-direction: column;
  height: 926px;
  overflow: hidden;
  width: 428px;
}

.close-giftaguess {
  cursor: pointer;
  height: 93px;
  left: -12px;
  object-fit: cover;
  position: fixed;
  top: -5px;
  transition: all 0.2s ease;
  width: 93px;
  z-index: 3;
}

.close-giftaguess:hover {
  filter: blur(3px);
}

.attach-guess {
  align-items: flex-start;
  display: flex;
  height: 336px;
  justify-content: center;
  margin-top: 38px;
  min-width: 404px;
  z-index: 1;
}

.overlap-group2-1 {
  height: 354px;
  margin-top: -6px;
  position: relative;
  width: 422px;
}

.frame-attatched {
  height: 256px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 98px;
  width: 422px;
}

.attatched-2 {
  align-items: flex-end;
  display: flex;
  height: 182px;
  left: 46px;
  min-width: 331px;
  position: absolute;
  top: 132px;
}

.attatch-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: -3.98px;
  margin-left: -9px;
  min-height: 181px;
  width: 80px;
}

.attatch-token-btn {
  cursor: pointer;
  height: 80px;
  object-fit: cover;
  transition: all 0.2s ease;
  width: 80px;
}

.attatch-token-btn:hover,
.attatch-nft-btn:hover {
  transform: scale(0.9); 
}

.attatch-nft-btn {
  cursor: pointer;
  height: 72px;
  margin-top: 29px;
  object-fit: cover;
  transition: all 0.2s ease;
  width: 80px;
}

.overlap-group-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: -2px;
  margin-left: 31px;
  min-height: 184px;
  width: 231px;
}

.attatched-token-a-container {
  height: 79px;
  position: relative;
  width: 231px;
}

.attatched {
  background-color: transparent;
  border: 0;
  height: 40px;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  padding: 0;
  position: absolute;
  top: 39px;
  width: 231px;
}

.attatched::placeholder,
.attatched-1::placeholder,
.word-to-guess::placeholder,
.guess-cost-set::placeholder {
  color: #48484899;
}

.attatched-1 {
  background-color: transparent;
  border: 0;
  height: 40px;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  padding: 0;
  position: absolute;
  top: 0;
  width: 231px;
}

.attatched-nft-container {
  height: 79px;
  margin-top: 26px;
  position: relative;
  width: 231px;
}

.frame-word-to-guess {
  height: 120px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 422px;
}

.word-to-guess {
  background-color: transparent;
  border: 0;
  height: 60px;
  left: 34px;
  letter-spacing: 0;
  line-height: 32px;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 36px;
  width: 356px;
}

.value {
  align-items: flex-end;
  display: flex;
  height: 83px;
  margin-right: 0;
  margin-top: 27px;
  min-width: 387px;
  z-index: 2;
}

.overlap-group1-4 {
  background-position: 50% 50%;
  background-size: cover;
  height: 94px;
  margin-bottom: -5.21px;
  margin-left: -9px;
  position: relative;
  width: 95px;
}

.ellipse-2-6 {
  background-color: var(--romance);
  border-radius: 15.35px;
  height: 31px;
  left: 57px;
  position: absolute;
  top: 58px;
  width: 31px;
}

.path-12-6 {
  height: 13px;
  left: 57px;
  object-fit: cover;
  position: absolute;
  top: 65px;
  width: 16px;
}

.path-13-6 {
  height: 10px;
  left: 73px;
  object-fit: cover;
  position: absolute;
  top: 58px;
  width: 9px;
}

.path-14-6 {
  height: 11px;
  left: 79px;
  object-fit: cover;
  position: absolute;
  top: 63px;
  width: 9px;
}

.path-15-6 {
  height: 10px;
  left: 57px;
  object-fit: cover;
  position: absolute;
  top: 73px;
  width: 11px;
}

.path-16-6 {
  height: 14px;
  left: 61px;
  object-fit: cover;
  position: absolute;
  top: 58px;
  width: 15px;
}

.path-17-6 {
  height: 14px;
  left: 76px;
  object-fit: cover;
  position: absolute;
  top: 71px;
  width: 12px;
}

.path-18-6 {
  height: 13px;
  left: 71px;
  object-fit: cover;
  position: absolute;
  top: 75px;
  width: 10px;
}

.path-19-6 {
  height: 9px;
  left: 62px;
  object-fit: cover;
  position: absolute;
  top: 80px;
  width: 11px;
}

.icon-awesome-dice-4 {
  height: 27px;
  left: 56px;
  object-fit: cover;
  position: absolute;
  top: 59px;
  width: 31px;
}

.guess-container-4 {
  height: 93px;
  margin-bottom: -7.6px;
  margin-left: 1px;
  position: relative;
  width: 309px;
}

.set-guess-btn {
  cursor: pointer;
  height: 93px;
  left: 217px;
  object-fit: cover;
  position: absolute;
  top: 0;
  transition: all 0.2s ease;
  width: 93px;
}

.set-guess-btn:hover {
  transform: rotate(-90deg);
}

.guess-cost-set {
  background-color: transparent;
  border: 0;
  height: 76px;
  left: 0;
  letter-spacing: 0;
  line-height: 62px;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 7px;
  width: 219px;
}

.posted {
  align-items: center;
  display: flex;
  height: 158px;
  margin-top: 46px;
  min-width: 392px;
  z-index: 4;
}

.guess-2 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 30px;
  box-shadow: 0px 3px 6px #cecece;
  display: flex;
  flex-direction: column;
  min-height: 158px;
  padding: 0 0.0px;
  width: 328px;
}

.guess-container-5 {
  height: 67px;
  position: relative;
  width: 307px;
}

.word-to-guess-1 {
  color: var(--dove-gray);
  font-family: var(--font-family-helveticaneue-condensedblack);
  font-size: var(--font-size-xs);
  font-weight: 900;
  left: 0;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  text-align: center;
  text-shadow: 0px 3px 6px #cecece;
  top: 34px;
  width: 289px;
}

.guessed-check {
  height: 35px;
  left: 272px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 35px;
}

.flex-row-2 {
  align-items: center;
  align-self: center;
  display: flex;
  margin-left: 6.52px;
  margin-top: 21px;
  min-width: 311px;
}

.overlap-group1-5 {
  align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 59px;
  justify-content: flex-end;
  min-width: 59px;
  padding: 8.4px 8.1px;
}

.group-6 {
  align-items: flex-end;
  background-color: var(--romance);
  border-radius: 8.21px;
  display: flex;
  justify-content: flex-end;
  min-width: 16px;
  padding: 0.0px 0;
}

.overlap-group-5 {
  height: 16px;
  position: relative;
  width: 17px;
}

.path-12-7 {
  height: 7px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 9px;
}

.path-13-7 {
  height: 5px;
  left: 9px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 5px;
}

.path-14-7 {
  height: 6px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 2px;
  width: 5px;
}

.path-15-7 {
  height: 5px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 8px;
  width: 6px;
}

.path-16-7 {
  height: 8px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 8px;
}

.path-17-7 {
  height: 7px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 7px;
  width: 6px;
}

.path-18-7 {
  height: 7px;
  left: 8px;
  object-fit: cover;
  position: absolute;
  top: 9px;
  width: 6px;
}

.path-19-7 {
  height: 5px;
  left: 3px;
  object-fit: cover;
  position: absolute;
  top: 12px;
  width: 6px;
}

.icon-awesome-dice-5 {
  height: 14px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 16px;
}

.tickets-collecteds {
  letter-spacing: 0;
  line-height: 32px;
  margin-left: 11px;
  margin-top: 5.97px;
  min-height: 40px;
  text-shadow: 0px 3px 6px #cecece;
  width: 147px;
}

.disable-guess {
  align-self: flex-end;
  cursor: pointer;
  height: 56px;
  margin-bottom: 0.03px;
  margin-left: 38px;
  object-fit: cover;
  transition: all 0.2s ease;
  width: 56px;
}

.disable-guess:hover {
  transform: scale(1.1); 
}

.posted-next {
  cursor: pointer;
  height: 59px;
  margin-bottom: 0;
  margin-left: 11px;
  object-fit: cover;
  transition: all 0.2s ease;
  width: 53px;
}

.posted-next:hover {
  transform: scale(0.7);
}






.overlap-group1-5 * {
  width: 40px;
  height: auto;
  top: -30px;
  left: 8px;
  border-radius: 20px;
}

.posted-next {
  padding: 5px;
  background: white;
  left: 5px;
  position: relative;
}






.screen {
  min-height: 100vh !important;
  height: 100vh !important;
  overflow: scroll;
}

