.nfts {
  background-color: var(--romance);
  display: flex;
  height: 100vh;
  min-height: 926px;
  min-width: 428px;
  overflow: hidden;
  width: 100%;
}

.nfts-boxed {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 270px;
  min-height: calc(100.00% - 270px);
  position: relative;
  width: 428px;
  z-index: 1;
}

.nft-container {
  align-items: center;
  background-color: var(--white);
  display: flex;
  height: 76px;
  justify-content: flex-end;
  min-width: 428px;
  padding: 19px 25.5px;
}

.loading-nfts {
  align-self: flex-end;
  letter-spacing: 0;
  line-height: 28px;
  min-height: 35px;
  text-align: center;
  text-shadow: 0px 3px 6px #cecece;
  width: 168px;
}

.create-nft-btn {
  cursor: pointer;
  height: 32px;
  margin-bottom: 0.5px;
  margin-left: 72px;
  object-fit: cover;
  transition: all 0.2s ease;
  width: 32px;
}

.create-nft-btn:hover,
.close-nfts:hover {
  filter: blur(3px);
}

.close-nfts {
  cursor: pointer;
  height: 93px;
  left: -12px;
  object-fit: cover;
  position: fixed;
  top: -5px;
  transition: all 0.2s ease;
  width: 93px;
  z-index: 2;
}







.loading-nfts {
  top: 40px;
  position: relative;
}







.landing {
  min-height: 100vh !important;
  height: 100vh !important;
  overflow: scroll;
}